import React from "react";

const EuroIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 163 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_2313)">
        <mask
          id="mask0_2_2313"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="13"
          y="3"
          width="143"
          height="155"
        >
          <path
            d="M69.2568 7.80069C78.6893 2.3548 90.3107 2.3548 99.7432 7.80069L139.838 30.9493C149.27 36.3952 155.081 46.4596 155.081 57.3514V103.649C155.081 114.54 149.27 124.605 139.838 130.051L99.7432 153.199C90.3107 158.645 78.6893 158.645 69.2568 153.199L29.1622 130.051C19.7296 124.605 13.9189 114.54 13.9189 103.649V57.3514C13.9189 46.4596 19.7296 36.3952 29.1622 30.9493L69.2568 7.80069Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2_2313)">
          <path
            d="M84.9999 162C129.735 162 166 125.735 166 81C166 36.2649 129.735 0 84.9999 0C40.2649 0 4 36.2649 4 81C4 125.735 40.2649 162 84.9999 162Z"
            fill="#0064DC"
          />
          <path
            d="M84.9998 32L87.5968 40.0213H96L89.2009 44.9787L91.798 53L84.9998 48.0424L78.2015 53L80.7986 44.9787L74 40.0213H82.4035L84.9998 32Z"
            fill="#FFDA44"
          />
          <path
            d="M50.1529 46.1532L57.7343 50.0156L63.7503 44L62.419 52.4029L70 56.2655L61.5967 57.5965L60.2657 66L56.403 58.4191L48 59.7504L54.0159 53.7345L50.1529 46.1532Z"
            fill="#FFDA44"
          />
          <path
            d="M36 80.9998L44.0213 78.4029V70L48.9783 76.7985L57 74.2016L52.042 80.9998L57 87.7981L48.9783 85.2011L44.0213 92V83.5971L36 80.9998Z"
            fill="#FFDA44"
          />
          <path
            d="M50.153 115.847L54.0158 108.266L48 102.25L56.4034 103.582L60.2655 96L61.5969 104.403L70 105.734L62.4198 109.597L63.7505 118L57.7345 111.984L50.153 115.847Z"
            fill="#FFDA44"
          />
          <path
            d="M84.9998 130L82.4027 121.979H74L80.7986 117.021L78.2015 109L84.9998 113.957L91.798 109L89.2009 117.021L96 121.979H87.5968L84.9998 130Z"
            fill="#FFDA44"
          />
          <path
            d="M119.847 115.846L112.266 111.984L106.25 118L107.581 109.596L100 105.734L108.403 104.403L109.734 96L113.597 103.581L122 102.25L115.984 108.266L119.847 115.846Z"
            fill="#FFDA44"
          />
          <path
            d="M134 80.9998L125.979 83.5971V92L121.021 85.2011L113 87.7981L117.957 80.9998L113 74.2016L121.021 76.7985L125.979 70V78.4032L134 80.9998Z"
            fill="#FFDA44"
          />
          <path
            d="M119.847 46.1529L115.984 53.7345L122 59.7505L113.597 58.4188L109.734 66L108.403 57.5965L100 56.2652L107.581 52.4027L106.25 44L112.266 50.0157L119.847 46.1529Z"
            fill="#FFDA44"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_2313">
          <rect width="162" height="162" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EuroIcon;
