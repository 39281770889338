import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link as MuiLink,
} from "@mui/material";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AccountBalanceWalletRoundedIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import MoveDownRounedIcon from "@mui/icons-material/MoveDownRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import { Support } from "@mui/icons-material";
import { logout } from "service/api";
import { useAccountQuery } from "../features/auth/authApi";
import * as sites from "sites.json";
const links = [
  {
    to: "/",
    label: "Dashboard",
    id: "dashboard",
    icon: <DashboardRoundedIcon />,
  },
  {
    to: "/wallet",
    label: "My Wallet",
    id: "wallet",
    icon: <AccountBalanceWalletRoundedIcon />,
  },
  {
    to: "/transfer",
    id: "transfer",
    label: "Transfer",
    icon: <MoveDownRounedIcon />,
  },
  {
    to: "/transactions",
    id: "transactions",
    label: "Transactions",
    icon: <ReceiptRoundedIcon />,
  },
  {
    to: "/profile",
    id: "profile",
    label: "Profile",
    icon: <AccountCircleRoundedIcon />,
  },
  {
    onClick: () => {
      logout().then(() => {
        window.location.reload();
      });
    },
    label: "Logout",
    icon: <LogoutRoundedIcon />,
  },
];
const DrawerContent = () => {
  const host = window.location.hostname;
  const siteEmail = sites[host as keyof typeof sites].siteEmail;

  const location = useLocation();
  const navigate = useNavigate();
  const cUsLink = `mailto:${siteEmail}`;
  const cUsText = siteEmail;
  const { data: account } = useAccountQuery();
  const isActive = (to?: string) => {
    return location.pathname === to;
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {links
          .filter(
            ({ id }) =>
              id !== "wallet" ||
              (id === "wallet" && !account?.user.kycProgram.manual_tx)
          )
          .map(({ to, label, icon, onClick }) => (
            <ListItem
              disablePadding
              onClick={onClick ? onClick : () => navigate(to)}
              key={label}
            >
              <ListItemButton selected={isActive(to)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
      </Box>
      <ListItem
        disablePadding
        component={MuiLink}
        href={cUsLink}
        target="_blank"
      >
        <ListItemButton>
          <ListItemIcon>
            <Support color="primary" />
          </ListItemIcon>
          <ListItemText>{cUsText}</ListItemText>
        </ListItemButton>
      </ListItem>
    </>
  );
};

export default DrawerContent;
