import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SnackbarProvider } from "notistack";
import React from "react";

export type Notification = {
  message: string;
  type: "success" | "error" | "info";
};

export type NotificationsInitialState = {
  items: Notification[];
  ref: React.RefObject<SnackbarProvider>;
};

export const initialState: NotificationsInitialState = {
  items: [],
  ref: React.createRef<SnackbarProvider>(),
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.items.push(action.payload);
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(
        (item) => item.message !== action.payload
      );
    },
    clearNotifications: (state) => {
      state.items = [];
    },
    successNotification: (state, action: PayloadAction<string>) => {
      state.items.push({
        message: action.payload,
        type: "success",
      });
    },
    errorNotification: (state, action: PayloadAction<string>) => {
      state.items.push({
        message: action.payload,
        type: "error",
      });
    },
    infoNotification: (state, action: PayloadAction<string>) => {
      state.items.push({
        message: action.payload,
        type: "info",
      });
    },
  },
});

export const {
  addNotification,
  clearNotifications,
  errorNotification,
  infoNotification,
  removeNotification,
  successNotification,
} = notificationsSlice.actions;
