import React from "react";
const BTCIcon: React.FC<{ size?: number }> = ({ size = 51 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 163 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.0539 8.80084C75.4864 3.35496 87.1078 3.35496 96.5404 8.80085L136.459 31.8481C145.892 37.294 151.703 47.3584 151.703 58.2502V104.345C151.703 115.237 145.892 125.301 136.459 130.747L96.5404 153.794C87.1078 159.24 75.4864 159.24 66.0539 153.794L26.1348 130.747C16.7023 125.301 10.8916 115.236 10.8916 104.345V58.2502C10.8916 47.3584 16.7023 37.294 26.1348 31.8481L66.0539 8.80084Z"
      fill="url(#paint0_linear_0_581)"
    />
    <path
      d="M108.238 73.8288C109.436 65.8203 103.339 61.5154 95.0013 58.6436L97.706 47.7953L91.1023 46.1498L88.4694 56.7125C86.7333 56.2795 84.9505 55.8715 83.1785 55.4671L85.8306 44.8347L79.231 43.1892L76.5248 54.034C75.0882 53.7069 73.6771 53.3836 72.3082 53.043L72.3158 53.0088L63.2092 50.7348L61.4525 57.7879C61.4525 57.7879 66.3519 58.911 66.2487 58.9801C68.9228 59.6475 69.4064 61.4177 69.3261 62.8208L66.2451 75.1796C66.4293 75.2263 66.6681 75.294 66.9317 75.3999C66.7114 75.3452 66.4769 75.2855 66.2337 75.2272L61.9152 92.54C61.5884 93.3525 60.7589 94.5718 58.8893 94.1088C58.9555 94.2047 54.0896 92.911 54.0896 92.911L50.811 100.47L59.4046 102.612C61.0034 103.013 62.57 103.433 64.1128 103.827L61.3802 114.8L67.9762 116.445L70.6824 105.589C72.4844 106.078 74.2331 106.53 75.945 106.955L73.248 117.76L79.8519 119.405L82.5843 108.453C93.845 110.584 102.312 109.725 105.876 99.54C108.748 91.3396 105.733 86.6097 99.8089 83.5252C104.124 82.5301 107.372 79.6913 108.238 73.8288ZM93.1502 94.9859C91.1094 103.186 77.3025 98.7534 72.8262 97.6418L76.4524 83.1049C80.9285 84.2224 95.2828 86.4338 93.1502 94.9859ZM95.1925 73.71C93.3308 81.1691 81.8392 77.3795 78.1115 76.4503L81.3992 63.2662C85.127 64.1954 97.1312 65.9297 95.1925 73.71Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_0_581"
        x1="81.2971"
        y1="4.71643"
        x2="81.2971"
        y2="157.878"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA45" />
        <stop offset="1" stopColor="#F19C3B" />
      </linearGradient>
    </defs>
  </svg>
);

export default BTCIcon;
