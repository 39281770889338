import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";
import { Crypto } from "types/crypto";
import { Fiat } from "types/fiat";

type ExchangeCurrenciesResponse = {
  data?: { crypto: Crypto[]; fiat: Fiat[] };
  result: boolean;
};
type ExchangeRatesResponse = {
  data?: {
    amount_crypto: number;
    amount_fiat: number;
    ask_fee_of_rate: string;
    ask_real: string;
    ask_with_fee: string;
    bid_fee_of_rate: string;
    bid_real: string;
    bid_with_fee: string;
    company_fee_rate_percent: string;
    created_at: string;
    exchange_type: string;
    id: number;
    login: string;
    symbol: string;
    timeExpiration: number;
    timeRateExpiration: number;
    company_fee: string;
    updated_at: string;
  }[];
  result: boolean;
};

type ExchangeCalculateResponse = {
  amount: string;
  result: boolean;
};

type ExchangeBuyResponse = {
  operationId: number;
  result: boolean;
  redirectUrl: string;
};

type ExchangeEffectiveUserCryptoBalanceResponse = {
  balance: number;
  result: boolean;
};
export const exchangeApi = createApi({
  reducerPath: "exchangeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseHref}/exchange`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    exchangeCurrencies: builder.query<ExchangeCurrenciesResponse, void>({
      query: () => `currencies`,
    }),
    exchangeRates: builder.query<ExchangeRatesResponse, Crypto>({
      query: (crypto) => `rates/${crypto}`,
    }),
    exchangeCalculate: builder.query<
      ExchangeCalculateResponse,
      Record<string, string>
    >({
      query: (payload) => ({
        url: `calculate`,
        method: "GET",
        params: payload,
      }),
    }),
    exchangeBuy: builder.mutation<ExchangeBuyResponse, Record<string, any>>({
      query: (payload) => ({
        url: `buy`,
        method: "POST",
        body: payload,
      }),
    }),
    exchangeSell: builder.mutation<ExchangeBuyResponse, Record<string, any>>({
      query: (payload) => ({
        url: `sell`,
        method: "POST",
        body: payload,
      }),
    }),
    exchangeEffectiveUserCryptoBalance: builder.query<
      ExchangeEffectiveUserCryptoBalanceResponse,
      Crypto
    >({
      query: (crypto) =>
        `get-effective-user-crypto-balance/${crypto.toUpperCase()}`,
    }),
  }),
});

export const {
  useExchangeCurrenciesQuery,
  useExchangeRatesQuery,
  useLazyExchangeCalculateQuery,
  useExchangeBuyMutation,
  useExchangeSellMutation,
  useLazyExchangeEffectiveUserCryptoBalanceQuery,
} = exchangeApi;
