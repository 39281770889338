import React from "react";
import {
  Box,
  Button,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Paper,
  Popper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import AvatarIcon from "@mui/icons-material/Person";
import ErrorIcon from "@mui/icons-material/Error";
import LogoutIcon from "@mui/icons-material/Logout";
import WalletIcon from "@mui/icons-material/AccountBalanceWalletRounded";
import { useAccountQuery } from "features/auth/authApi";
import theme from "theme";
import { logout } from "service/api";
import { Link, useNavigate } from "react-router-dom";

const iconBackColorOpen = "grey.300";

const Profile = () => {
  const { data: account } = useAccountQuery();
  const navigate = useNavigate();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const handleLogout = async () => {
    logout().then(() => {
      window.location.reload();
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const open = Boolean(anchorEl);

  const id = open ? "header-profile-menu" : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      {account?.user.wallet.kyc_program_status === 0 && (
        <ButtonBase
          sx={{
            p: 0.25,
            bgcolor: "warning.main",
            color: "error.contrastText",
            borderRadius: 1,
            mr: 1,
          }}
          onClick={() => navigate("/profile/documents")}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ p: 0.5 }}
          >
            <ErrorIcon />
            <Typography variant="subtitle1">KYC Not Passed</Typography>
          </Stack>
        </ButtonBase>
      )}
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,
          "&:hover": { bgcolor: "secondary.lighter" },
        }}
        onClick={handleClick}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <AvatarIcon />
          {!matchDownSm && (
            <Typography variant="subtitle1">
              {[account?.user.first_name, account?.user.last_name].join(" ")}
            </Typography>
          )}
        </Stack>
      </ButtonBase>
      <Popper id={id} open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Paper id="profile-grow" elevation={4} sx={{ minWidth: 180 }}>
            <CardContent />
            <Stack spacing={1} sx={{ px: 2, pb: 2 }}>
              <Button
                variant="outlined"
                component={Link}
                to="/profile"
                sx={{ gap: 1, justifyContent: "flex-start" }}
              >
                <AvatarIcon />
                Profile
              </Button>
              {!account?.user.kycProgram.manual_tx && (
                <Button
                  variant="outlined"
                  component={Link}
                  to="/wallet"
                  sx={{ gap: 1, justifyContent: "flex-start" }}
                >
                  <WalletIcon />
                  My Wallet
                </Button>
              )}
              <Button
                aria-label="logout"
                variant="outlined"
                color="warning"
                sx={{ gap: 1, justifyContent: "flex-start" }}
                onClick={handleLogout}
              >
                <LogoutIcon />
                Logout
              </Button>
            </Stack>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default Profile;
