import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Transaction } from "types/transaction";

type AppState = {
  isDrawerOpen: boolean;
  transactionDetailModal: null | Transaction;
  transactionBumpFeeModal: null | Transaction;
  isOtpEnableModalOpen: boolean;
  isChangePasswordModalOpen: boolean;
  isGenerateApiKeyModalOpen: boolean;
  isUserLoading: boolean;
  selectedTransactionDocumentUploader: null | Transaction;
};

const initialState: AppState = {
  isDrawerOpen: true,
  transactionDetailModal: null,
  transactionBumpFeeModal: null,
  isOtpEnableModalOpen: false,
  isChangePasswordModalOpen: false,
  isGenerateApiKeyModalOpen: false,
  isUserLoading: false,
  selectedTransactionDocumentUploader: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setTransactionDetailModal: (
      state,
      action: PayloadAction<Transaction | null>
    ) => {
      state.transactionDetailModal = action.payload;
    },
    setTransactionBumpFeeModal: (
      state,
      action: PayloadAction<Transaction | null>
    ) => {
      state.transactionBumpFeeModal = action.payload;
    },
    toggleOtpEnableModal: (state) => {
      state.isOtpEnableModalOpen = !state.isOtpEnableModalOpen;
    },
    changePasswordModal: (state) => {
      state.isChangePasswordModalOpen = !state.isChangePasswordModalOpen;
    },
    toggleIsGenerateApiKeyModalOpen: (state) => {
      state.isGenerateApiKeyModalOpen = !state.isGenerateApiKeyModalOpen;
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserLoading = action.payload;
    },
    setSelectedTransactionDocumentUploader: (
      state,
      action: PayloadAction<Transaction | null>
    ) => {
      state.selectedTransactionDocumentUploader = action.payload;
    },
  },
});

export const {
  toggleDrawer,
  setTransactionDetailModal,
  setTransactionBumpFeeModal,
  toggleOtpEnableModal,
  changePasswordModal,
  toggleIsGenerateApiKeyModalOpen,
  setUserLoading,
  setSelectedTransactionDocumentUploader,
} = appSlice.actions;
