import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";
import { Asset } from "types/asset";

type AssetsResponse = {
  list: Asset[];
};

export const listApi = createApi({
  reducerPath: "listApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseHref}/list`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    assets: builder.query<AssetsResponse, void>({
      query: () => `assets`,
    }),
  }),
});

export const { useAssetsQuery } = listApi;
