import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Crypto } from "types/crypto";

type Type = {
  selectedCrypto: Crypto;
  selectedTab: string;
};

const initialState: Type = {
  selectedCrypto: "btc",
  selectedTab: "Send",
};

export const transferPageSlice = createSlice({
  name: "transferPage",
  initialState,
  reducers: {
    setSelectedCrypto: (state, action: PayloadAction<Crypto>) => {
      state.selectedCrypto = action.payload;
    },
    setSelectedTab: (state, action: PayloadAction<string>) => {
      state.selectedTab = action.payload;
    },
  },
});
export const { setSelectedCrypto, setSelectedTab } = transferPageSlice.actions;
