import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "features/auth/authApi";
import { notificationsSlice } from "features/notifications/notificationsSlice";
import { appSlice } from "features/app/appSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { listApi } from "features/list/listApi";
import { financeApi } from "features/finance/financeApi";
import { feesApi } from "features/fees/feesApi";
import { transferPageSlice } from "features/transfer-page/transferPageSlice";
import { exchangeApi } from "features/exchange/exchangeApi";
import { countriesApi } from "features/counteris/countriesApi";
export const store = configureStore({
  reducer: {
    notifications: notificationsSlice.reducer,
    app: appSlice.reducer,
    transferPage: transferPageSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [listApi.reducerPath]: listApi.reducer,
    [financeApi.reducerPath]: financeApi.reducer,
    [feesApi.reducerPath]: feesApi.reducer,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(listApi.middleware)
      .concat(financeApi.middleware)
      .concat(feesApi.middleware)
      .concat(exchangeApi.middleware)
      .concat(countriesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
