import React, { useId } from "react";

const RippleIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  const id = useId();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 164 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.4863 8.80084C75.9188 3.35496 87.5402 3.35496 96.9727 8.80085L136.892 31.8481C146.324 37.294 152.135 47.3584 152.135 58.2502V104.345C152.135 115.237 146.324 125.301 136.892 130.747L96.9727 153.794C87.5402 159.24 75.9188 159.24 66.4863 153.794L26.5672 130.747C17.1347 125.301 11.324 115.236 11.324 104.345V58.2502C11.324 47.3584 17.1347 37.294 26.5672 31.8481L66.4863 8.80084Z"
        fill={`url(#${id})`}
      />
      <path
        d="M119.689 50.8108H108.674L91.2436 68.0532C88.706 70.5542 85.2862 71.9562 81.7233 71.9562C78.1603 71.9562 74.7405 70.5542 72.2029 68.0532L54.7863 50.8108H43.7618L66.6951 73.5089C75.003 81.7245 88.4629 81.7245 96.7648 73.5089L119.689 50.8108Z"
        fill="white"
      />
      <path
        d="M43.6216 113.927H54.6446L72.2161 96.5447C74.7536 94.0437 78.1735 92.6417 81.7364 92.6417C85.2993 92.6417 88.7191 94.0437 91.2567 96.5447L108.822 113.927H119.838L96.7645 91.089C88.4567 82.8734 74.9968 82.8734 66.6948 91.089L43.6216 113.927Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id={id}
          x1="81.7295"
          y1="4.71643"
          x2="81.7295"
          y2="157.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3FA862" />
          <stop offset="1" stopColor="#348D52" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RippleIcon;
