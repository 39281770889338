import React from "react";

const USDTIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 164 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.8647 8.80084C76.2972 3.35496 87.9186 3.35496 97.3512 8.80085L137.27 31.8481C146.703 37.294 152.513 47.3584 152.513 58.2502V104.345C152.513 115.237 146.703 125.301 137.27 130.747L97.3511 153.794C87.9186 159.24 76.2972 159.24 66.8647 153.794L26.9456 130.747C17.5131 125.301 11.7024 115.236 11.7024 104.345V58.2502C11.7024 47.3584 17.5131 37.294 26.9456 31.8481L66.8647 8.80084Z"
        fill="url(#paint0_linear_0_613)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M89.3449 83.4926C88.9407 83.5229 86.8523 83.6476 82.194 83.6476C78.4889 83.6476 75.8582 83.5364 74.9353 83.4926C60.6167 82.8627 49.929 80.3702 49.929 77.3859C49.929 74.4016 60.6167 71.9124 74.9353 71.2724V81.0102C75.8717 81.0775 78.5529 81.2359 82.258 81.2359C86.7041 81.2359 88.9306 81.0506 89.3314 81.0135V71.2791C103.62 71.9158 114.284 74.4083 114.284 77.3859C114.284 80.3635 103.623 82.856 89.3314 83.4892L89.3449 83.4926ZM89.3449 70.272V61.5582H109.285V48.2703H54.995V61.5582H74.9319V70.2687C58.727 71.013 46.5405 74.223 46.5405 78.0696C46.5405 81.9163 58.727 85.1229 74.9319 85.8706V113.794H89.3415V85.8605C105.509 85.1161 117.676 81.9095 117.676 78.0663C117.676 74.223 105.519 71.0164 89.3415 70.2687L89.3449 70.272Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_613"
          x1="82.1079"
          y1="4.71643"
          x2="82.1079"
          y2="157.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3FA862" />
          <stop offset="1" stopColor="#348D52" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default USDTIcon;
