import axios, { AxiosResponse } from "axios";
export const baseHref = process.env.REACT_APP_API_BASE_HREF || "/api";
axios.defaults.withCredentials = true;
export const endPoint = (
  type: string,
  query: Record<string, any> | null = null
) => {
  const url = `${baseHref}${type}`;

  if (query) {
    const params = new URLSearchParams({
      ...query,
    });
    return `${url}?${params.toString()}`;
  }

  return url;
};
export let requestPayload: Record<string, any> = {};

export const getPreviousRequestPayload = (path: string) => {
  return requestPayload[endPoint(path)];
};

axios.interceptors.request.use((request) => {
  if (request.url) requestPayload[request.url] = request.data;
  return request;
});

axios.interceptors.response.use((response) => {
  if (response.config.url && response?.data?.error?.code !== 1017) {
    requestPayload[response.config.url] = undefined;
  }

  return response;
});

export const extract = (r: AxiosResponse | any) => r.data;

export type ParseData = {
  result?: boolean;
  errors?: string[];
  error?: {
    code: number;
    message: string;
  };
  list?: string[];
  files?: string[];
  [key: string]: any;
};

export const parseResult = (data: ParseData) => {
  if (!data.result) {
    if (data.errors && Array.isArray(data.errors)) {
      throw data.errors.join("; ");
    }

    throw data.error;
  }

  return data as any;
};

export const getAccount = async () => {
  const r = await axios.get(endPoint("/account"));
  return extract(r);
};

export const login = async (login: string, password: string, otp?: string) => {
  const r = await axios.post(endPoint("/login"), { login, password, otp });
  const data = await extract(r);
  return parseResult(data);
};

export const fetchWalletTypes = async () => {
  const r = await axios.get(endPoint("/wallet/types"));
  return extract(r);
};

export type SignUpParams = {
  email: string;
  first_name: string;
  last_name: string;
  wallet_type_id: string;
  name: string;
  company_email: string;
  company_number: string;
  phone: string;
  website: string;
  first_name_director: string;
  last_name_director: string;
  country: string;
  city: string;
  street: string;
  postal_code: string;
  description: string;
};

export const signUp = async ({
  email,
  first_name,
  last_name,
  wallet_type_id,
  name,
  company_email,
  company_number,
  phone,
  website,
  first_name_director,
  last_name_director,
  country,
  city,
  street,
  postal_code,
  description,
}: SignUpParams) => {
  const r = await axios.post(endPoint("/register"), {
    email,
    first_name,
    last_name,
    wallet_type_id: wallet_type_id === "Private Wallet" ? "0" : "1",
    name,
    company_email,
    company_number,
    phone,
    website,
    first_name_director,
    last_name_director,
    country,
    city,
    street,
    postal_code,
    description,
  });
  const data = await extract(r);
  return parseResult(data);
};

export type RequestPassRecoveryParams = {
  email: string;
};
export const requestPassRecovery = async ({
  email,
}: RequestPassRecoveryParams) => {
  const r = await axios.post(endPoint("/request/passrecovery"), { email });
  const data = await extract(r);
  return parseResult(data);
};

export const requestPassChange = () =>
  axios.post(endPoint("/request/passchange")).then(extract).then(parseResult);

export type SetPasswordParams = {
  password: string;
  token: string;
};
export const setPassword = async ({ token, password }: SetPasswordParams) => {
  const r = await axios.post(endPoint("/recover_password"), {
    password,
    token,
  });
  const data = await extract(r);
  return parseResult(data);
};

export const getDefaultKycPrograms = async () => {
  const r = await axios.get(endPoint("/get-default-kyc-programs"));
  const data = await extract(r);
  return parseResult(data);
};

export const fetchBalances = async () => {
  const r = await axios.get(endPoint("/finance/balances"));
  const data = await extract(r);
  return parseResult(data);
};

export const getLastAddress = async (asset: string) => {
  const r = await axios.get(endPoint(`/finance/address/${asset}`));
  return extract(r);
};

export const getNewAddress = async (asset: string) => {
  const response = await axios.post(endPoint(`/finance/address/${asset}`));
  return extract(response);
};

export const getNewAddressForManualSell = async (asset: string) => {
  const response = await axios.get(
    endPoint(`/exchange/get-manual-sell-address/${asset}`)
  );

  return extract(response);
};

export const getMaxWithdrawAmount = (fee: number, asset = "btc") =>
  axios
    .get(endPoint("/get_withdraw_all_values", { asset, fee }))
    .then(extract)
    .then(parseResult);

export const fetchTransactions = async (
  asset: string,
  count = 100,
  offset = 0,
  filter: Record<string, any> = {}
) => {
  const r = await axios.get(
    endPoint(`/finance/transactions/${asset}`, { count, offset, ...filter })
  );
  return extract(r);
};

export const checkStatusOfPayment = async (orderId: string) => {
  const r = await axios.get(
    endPoint(`/paytechno/check/status?orderId=${orderId}`)
  );
  return extract(r);
};

export const fetchFiatTransactions = async (
  asset: string,
  count = 100,
  offset = 0
) => {
  const r = await axios.get(
    endPoint(`/finance/transactions/fiat/${asset}`, { count, offset })
  );
  return extract(r);
};

export const fetchFeeTxs = async (asset: string, count = 100, offset = 0) => {
  const r = await axios.get(
    endPoint(`/fee_transactions/${asset}`, { count, offset })
  );
  return extract(r);
};

export const fetchAdminBalances = async () => {
  const r = await axios.get(endPoint("/admin/balances"));
  return extract(r);
};

export type FiatFromCardParams = {
  amount: number;
  currency: string;
  phone: string;
  street: string;
  city: string;
  zip: string;
  state: string;
  country: string;
};

export const fiatFromCard = async ({
  currency,
  amount,
  phone,
  street,
  city,
  zip,
  state,
  country,
}: FiatFromCardParams) => {
  const r = await axios.post(endPoint("/paytechno/fiat"), {
    currency,
    amount,
    phone,
    street,
    city,
    zip,
    state,
    country,
  });
  const data = await extract(r);
  return parseResult(data);
};

export const exchangeBuy = async (params: Record<string, any>) => {
  const r = await axios.post(endPoint("/exchange/buy"), params);
  const data = await extract(r);
  return parseResult(data);
};

export const exchangeSell = async (params: Record<string, any>) => {
  const r = await axios.post(endPoint("/exchange/sell"), params);
  const data = await extract(r);
  return parseResult(data);
};

export const getFiatTransactions = () => {};
export type InitGetIdParams = Partial<{
  getIdUrl: string;
  idp: string;
  flowName: string;
  apiKeyGetId: string;
  firstName: string;
  lastName: string;
}>;

export const initGetId = ({
  getIdUrl,
  idp,
  flowName,
  apiKeyGetId = "",
  firstName,
  lastName,
}: InitGetIdParams) => {
  return axios.post(
    `${getIdUrl}api/v1/tokenized-url?name=${flowName}&externalId=${idp}`,
    {
      redirects: {
        onComplete: `${process.env.REACT_APP_GET_ID_CALLBACK_BASE_URL}/getid/complete?externalId=${idp}&flowName=${flowName}`,
        onError: `${process.env.REACT_APP_GET_ID_CALLBACK_BASE_URL}/getid/error?error_code=609&externalId=${idp}&flowName=${flowName}`,
      },
      profile: {
        "First name": firstName,
        "Last name": lastName,
      },
    },
    {
      withCredentials: false,
      // crossDomain: true,
      headers: {
        apiKeyGetId,
        "Content-Type": "application/json",
        "X-API-Key": process.env.REACT_APP_GET_ID_API_KEY || "",
      },
    }
  );
};

export const logout = async () => {
  const r = await axios.post(endPoint("/logout"));
  return extract(r);
};

export const requestOtpCode = async () => {
  const r = await axios.get(endPoint("/request-otp-code"));
  return extract(r);
};

export const updateCompany = async (
  id: number,
  toUpdate: Record<string, any>
) => {
  const r = await axios.put(endPoint(`/company/${id}`), toUpdate);
  const data = await extract(r);
  return parseResult(data);
};

export const updateUser = async (toUpdate: Record<string, any>) => {
  let data: Record<string, any> = {};
  if (toUpdate.first_name) {
    data.first_name = toUpdate.first_name;
  }
  if (toUpdate.last_name) {
    data.last_name = toUpdate.last_name;
  }
  if (toUpdate.phone) {
    data.phone = toUpdate.phone;
  }
  if (toUpdate.idle_logout_time) {
    data.idle_logout_time = toUpdate.idle_logout_time;
  }
  if (toUpdate.otp_enabled) {
    data.otp_enabled = toUpdate.otp_enabled;
  }
  if (toUpdate.otp) {
    data.otp = toUpdate.otp;
  }
  if (toUpdate.avatar) {
    data.avatar = toUpdate.avatar;
  }
  if (toUpdate.id_doc) {
    data.id_doc = toUpdate.id_doc;
  }
  if (toUpdate.address_doc) {
    data.address_doc = toUpdate.address_doc;
  }
  if (toUpdate.toc_approved) {
    data.toc_approved = toUpdate.toc_approved;
  }
  if (toUpdate.sms_code) {
    data.sms_code = toUpdate.sms_code;
  }

  if (toUpdate.country) {
    data.country = toUpdate.country;
  }

  if (Object.keys(data).length === 0) {
    return Promise.resolve({});
  }

  const r = await axios.post(endPoint("/account"), data);
  const data_1 = await extract(r);
  return parseResult(data_1);
};

export type UpdatePasswordParams = {
  old_password: string;
  password: string;
};
export const updatePassword = async ({
  password,
  old_password,
}: UpdatePasswordParams) => {
  const r = await axios.post(endPoint("/account/password"), {
    password,
    old_password,
  });
  const data = await extract(r);
  return parseResult(data);
};

export type SendFundsParams = {
  asset: string;
  amount: number;
  address: string;
  otp?: string;
  fee?: number;
  replaceable?: boolean;
  subtract?: boolean;
};

export const sendFunds = async ({
  asset,
  amount,
  address,
  otp,
  fee,
  replaceable,
  subtract = false,
}: SendFundsParams) => {
  const r = await axios.post(endPoint("/finance/send"), {
    asset,
    amount,
    address,
    otp,
    fee,
    subtract,
    replaceable,
  });
  const data = await extract(r);
  return parseResult(data);
};

export const downloadDocWalletFile = (doc_wallet_id: string) => {
  return axios.get(endPoint(`/get_doc_wallet_file/${doc_wallet_id}`), {
    responseType: "blob",
  });
};
export const fetchFeeRates = async (asset: string) => {
  const r = await axios.get(endPoint(`/fees`, { asset }));
  const data = await extract(r);
  return parseResult(data);
};

export type GetRequiredTransactionDocumentsParams = {
  transId: number;
};

export const getRequiredTransactionDocuments = async ({
  transId,
}: GetRequiredTransactionDocumentsParams) => {
  const r = await axios.get(
    endPoint("/get-required-transaction-documents", { trans_id: transId })
  );
  const data = await extract(r);
  return parseResult(data);
};

export type UploadKycFileParams = {
  name: string;
  file: string;
  assignId: string;
  documentId: string;
};

export const uploadKycFile = async ({
  name,
  file,
  assignId,
  documentId,
}: UploadKycFileParams) => {
  const formData = new FormData();
  formData.append(name, file);
  formData.append("assign_id", assignId);
  formData.append("document_id", documentId);
  const r = await axios.post(endPoint("/upload"), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = await extract(r);
  parseResult(data);
};

export const uploadFile = async (name: string, file: string) => {
  const formData = new FormData();
  formData.append(name, file);

  const r = await axios.post(endPoint("/upload"), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  const data = await extract(r);
  const res = await parseResult(data);
  if (res.files) {
    // @ts-ignore
    return res?.files[name];
  }
  return undefined;
};

export const uploadExcelFile = async (file: string) => {
  const formData = new FormData();
  formData.append("tx-list-excel-file", file);

  const r = await axios.post(
    endPoint("/exchange/upload-excel-for-batch-transactions"),
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  const data = await extract(r);
  const res = await parseResult(data);
  return res;
};

export const getTemplate = (name: string) =>
  axios.get(endPoint(`/templates/${name}`), {
    responseType: "blob",
  });

export const getOtpToken = () =>
  axios.get(endPoint("/account/otp")).then(extract).then(parseResult);

export const fetchApiData = async (otp = "") => {
  const r = await axios.get(endPoint("/account/api_data", { otp }));
  return extract(r);
};
export const fetchGenerateKeys = async (otp: string) => {
  const r = await axios.post(endPoint("/account/generate_keys"), { otp });
  const data = await extract(r);
  return parseResult(data);
};
export const updateWhitelist = async (otp: string, ips?: string) => {
  const r = await axios.post(endPoint("/account/api/whitelist"), { otp, ips });
  const data = await extract(r);
  return parseResult(data);
};

// Admin
export const fetchSettings = () =>
  axios.get(endPoint("/settings")).then(extract).then(parseResult);

export const saveSetting = (values: Record<string, any>) =>
  axios.post(endPoint("/settings"), { values }).then(extract).then(parseResult);

export const fetchApiUsersData = async (count = 10, offset = 0) => {
  const r = await axios.get(endPoint("/users", { count, offset }));
  return extract(r);
};

export const fetchApiSetFee = async (idp: string, fee: string) => {
  const r = await axios.post(endPoint("/users/set_system_fee", { idp, fee }));
  const data = await extract(r);
  return parseResult(data);
};

export const fetchApiSetFeeMax = async (idp: string, fee: string) => {
  const r = await axios.post(
    endPoint("/users/set_max_network_fee", { idp, fee })
  );
  const data = await extract(r);
  return parseResult(data);
};

export const get2FaChannel = () =>
  axios.get(endPoint("/get2faChannel")).then(extract).then(parseResult);

export const updateUserSetting = async (name: string, value: string) => {
  const r = await axios.post(endPoint("/account/settings"), { name, value });
  const data = await extract(r);
  return parseResult(data);
};

export const apiBumpFee = (txid?: string, fee?: string) =>
  axios
    .post(endPoint("/finance/bump_fee"), { txid, fee })
    .then(extract)
    .then(parseResult);

export const apiEstimateBump = (txid?: string) =>
  axios
    .get(endPoint("/finance/estimate_bump", { txid }))
    .then(extract)
    .then(parseResult);

export const cancelTransaction = (id: number) =>
  axios
    .post(endPoint("/finance/cancel_wr"), { id })
    .then(extract)
    .then(parseResult);

export const fetchSwapInfo = async (query: Record<string, any>) => {
  const r = await axios.get(endPoint("/swap/info", query));
  return extract(r);
};

export const fetchSwapLimits = async (from: string, to: string) => {
  const r = await axios.get(endPoint("/swap/limits", { from, to }));
  return extract(r);
};

export const createSwap = async (rate_id: string, fee_from: string) => {
  const r = await axios.post(endPoint("/swap"), { rate_id, fee_from });
  return extract(r);
};

export const fetchAssets = () =>
  axios.get(endPoint("/list/assets")).then(extract);

export const fetchCurrencyExchangeRate = (currency: string) =>
  axios.get(endPoint(`/exchange/rates/${currency}`)).then(extract);
export const fetchCurrenciesExchange = () =>
  axios.get(endPoint("/exchange/currencies")).then(extract);

//
// @param {object} params
// @param {string} params.rate
// @param {string} params.amountFiat
// @param {string} params.amountCrypto
// @param {string} params.currencyTo
// @returns {Promise<any>}
//
export const fetchCalculateExchange = (props: Record<string, any>) =>
  axios.get(endPoint("/exchange/calculate", props)).then(extract);
