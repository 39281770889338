import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAccountQuery } from "features/auth/authApi";
import MainLayout from "layout/main";
import Loading from "components/loading";
const Login = lazy(() => import("pages/login"));
const SignUp = lazy(() => import("pages/sign-up"));
const ResetPassword = lazy(() => import("pages/reset-password"));
const SetPassword = lazy(() => import("pages/set-password"));
const EmailConfirmed = lazy(() => import("pages/email-confirmed"));
const Dashboard = lazy(() => import("pages/dashboard"));
const Wallet = lazy(() => import("pages/wallet"));
const Transactions = lazy(() => import("pages/transactions"));
const Profile = lazy(() => import("pages/profile"));
const ProfileDocuments = lazy(() => import("pages/profile-documents"));
const Toc = lazy(() => import("pages/toc"));
const Transfer = lazy(() => import("pages/transfer"));

const AppRouter: React.FC = () => {
  const { data: user, isLoading } = useAccountQuery();

  if (isLoading) return <Loading />;
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {!user?.result && (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/set-password/:token" element={<SetPassword />} />
            </>
          )}
          {user?.result && (
            <>
              <Route path="/" element={<MainLayout />}>
                <Route path="/" element={<Dashboard />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/toc" element={<Toc />} />
                <Route path="/transfer" element={<Transfer />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/profile" element={<Profile />} />
                <Route
                  path="/profile/documents"
                  element={<ProfileDocuments />}
                />
              </Route>
              <Route path="/email_confirmed" element={<EmailConfirmed />} />
            </>
          )}
          {/* if !user.result then redirect with callbackUrl */}
          <Route
            path="*"
            element={
              <Navigate
                replace
                to={
                  !user?.result
                    ? `/?callbackUrl=${encodeURIComponent(
                        window.location.pathname + window.location.search
                      )}`
                    : "/"
                }
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default AppRouter;
