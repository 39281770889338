import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";

type AssetFeesResponse = {
  rates?: {
    max: number;
    mid: number;
    min: number;
  };
  result: boolean;
};
export const feesApi = createApi({
  reducerPath: "feesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseHref,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    assetFees: builder.query<AssetFeesResponse, { asset: string }>({
      query: (query) => `fees?${new URLSearchParams(query).toString()}`,
    }),
  }),
});

export const { useAssetFeesQuery } = feesApi;
