import Drawer from "./drawer";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./header";
import { Box, Toolbar } from "@mui/material";
import TocCheck from "components/toc-check";
import OtpChangedCheck from "components/otp-changed-check";
const MainLayout = () => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <TocCheck />
      <OtpChangedCheck />
      <Header />
      <Drawer />
      <Box
        component="main"
        sx={{
          width: "100%",
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          bgcolor: "background.default",
          minHeight: "calc(100vh - 50px)",
          height: "100%",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainLayout;
