import React from "react";
import { useTheme } from "@mui/material/styles";
import { Stack, Chip } from "@mui/material";
import * as sites from "sites.json";
import DrawerHeaderStyled from "./drawer-header-styled";
import { Link } from "react-router-dom";

const DrawerHeader: React.FC<{ open?: boolean }> = ({ open }) => {
  const theme = useTheme();
  const host = window.location.hostname;
  const logoSrc = sites[host as keyof typeof sites].smallLogo;

  return (
    //@ts-ignore
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Link to={"/"}>
          {/*account?.user.kycProgram.logo ? (
            <img
              src={account?.user.kycProgram.logo.base64Image}
              alt="logo"
              style={{ width: 200, height: 48, objectFit: "cover" }}
            />
          ) : (
            <Logo width={200} height={48} />
          )*/}
          <img src={"/" + logoSrc} alt="logo" width={"200"} />
        </Link>
        <Chip
          label={`v${process.env.REACT_APP_VERSION || "0.0.0"}`}
          size="small"
          color="primary"
          sx={{
            height: 16,
            "& .MuiChip-label": { fontSize: "0.625rem", py: 0.25 },
          }}
          component={Link}
          to={"/"}
          clickable
        />
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
