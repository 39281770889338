import { useAccountQuery } from "features/auth/authApi";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const TocCheck: React.FC = () => {
  const { data: account } = useAccountQuery();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!account?.user.toc_approved && location.pathname !== "/toc") {
      navigate("/toc");
    }
    if (account?.user.toc_approved && location.pathname === "/toc") {
      navigate("/");
    }
  });
  return null;
};

export default TocCheck;
