export const formatNumber = (
  num?: number | string,
  options?: {
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  }
) => {
  const result = Number(num).toLocaleString("en-US", {
    minimumFractionDigits: options?.minimumFractionDigits || 0,
    maximumFractionDigits: options?.maximumFractionDigits || 8,
  });
  return result;
};
