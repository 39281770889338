import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";
import { User } from "types/user";
import { Settings } from "types/settings";
type AccountResponse = {
  files: any;
  result: boolean;
  settings: Settings;
  user: User;
};

type AccountApiData = {
  ips: string;
  secret: string;
};

type OtpTokenResponse = {
  token: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseHref}/account`,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    account: builder.query<AccountResponse, void>({
      query: () => ``,
    }),
    accountApiData: builder.query<AccountApiData, { otp: string } | void>({
      query: (payload) => ({
        url: `api_data`,
        method: "GET",
        params: payload || {},
      }),
    }),
    otpToken: builder.query<OtpTokenResponse, void>({
      query: () => "otp",
    }),
    otpTokenSms: builder.query<AccountResponse, void>({
      query: () => `otp_sms`,
    }),
    otpTokenEmail: builder.query<AccountResponse, void>({
      query: () => `otp_email`,
    }),
  }),
});

export const {
  useAccountQuery,
  useLazyOtpTokenQuery,
  useLazyOtpTokenSmsQuery,
  useLazyOtpTokenEmailQuery,
  useAccountApiDataQuery,
} = authApi;
