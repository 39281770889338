import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useAccountQuery } from "features/auth/authApi";
import { useBalancesQuery } from "features/finance/financeApi";
import React, { useEffect, useMemo } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import CurrencyIcon from "components/currency-icon";
import { formatNumber } from "utils/formatNumber";
import numeral from "numeral";
type Props = {
  isSmall?: boolean;
};
const BalanceInfo: React.FC<Props> = ({ isSmall }) => {
  const [selectedAsset, setSelectedAsset] = React.useState<string>("");
  const { data: balances, isLoading } = useBalancesQuery(undefined, {
    pollingInterval: 60000,
  });

  const { data: account } = useAccountQuery(undefined, {
    pollingInterval: 60000,
  });
  const default_currency = account?.user.kycProgram.default_currency;

  useEffect(() => {
    if (!selectedAsset && account?.user.kycProgram.supported_cryptos[0]) {
      setSelectedAsset(account.user.kycProgram.supported_cryptos[0]);
    }
  }, [account, selectedAsset]);

  const rate = useMemo(() => {
    if (default_currency) {
      return Number(
        balances?.rates[
          selectedAsset.split("_")[0] as keyof typeof balances.rates
        ]?.[default_currency] || 0
      );
    }
    return 0;
  }, [balances, default_currency, selectedAsset]);

  const handlePrev = () => {
    if (account?.user.kycProgram.supported_cryptos) {
      const index =
        account.user.kycProgram.supported_cryptos.indexOf(selectedAsset);
      if (index > 0) {
        setSelectedAsset(account.user.kycProgram.supported_cryptos[index - 1]);
      }
    }
  };

  const nextItem = () => {
    if (account) {
      const index =
        account.user.kycProgram.supported_cryptos.indexOf(selectedAsset);
      return account.user.kycProgram.supported_cryptos[index + 1];
    }
  };

  const prevItem = () => {
    if (account) {
      const index =
        account.user.kycProgram.supported_cryptos.indexOf(selectedAsset);
      return account.user.kycProgram.supported_cryptos[index - 1];
    }
  };

  const handleNext = () => {
    if (account?.user.kycProgram.supported_cryptos) {
      const index =
        account.user.kycProgram.supported_cryptos.indexOf(selectedAsset);
      if (index < account.user.kycProgram.supported_cryptos.length - 1) {
        setSelectedAsset(account.user.kycProgram.supported_cryptos[index + 1]);
      }
    }
  };

  return (
    <Stack
      direction={isSmall ? "column" : "row"}
      spacing={2}
      divider={<Divider orientation="vertical" flexItem />}
    >
      <Box justifyContent={"space-between"} display={"flex"}>
        <Tooltip title="Previous">
          <IconButton
            sx={{ alignSelf: "center" }}
            color="primary"
            onClick={handlePrev}
            disabled={!prevItem()}
          >
            <ArrowLeftIcon />
          </IconButton>
        </Tooltip>
        {isSmall ? (
          <Tooltip title="Next">
            <IconButton
              color="primary"
              onClick={handleNext}
              disabled={!nextItem()}
            >
              <ArrowRightIcon />
            </IconButton>
          </Tooltip>
        ) : null}
      </Box>
      <Stack spacing={0.2}>
        <Typography fontWeight={600} fontSize={12}>
          Available
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={13}
          alignItems="center"
          display="flex"
          gap={0.5}
        >
          <>
            <CurrencyIcon currency={selectedAsset} size={20} />
            {isLoading ? (
              <CircularProgress size={14} />
            ) : (
              formatNumber(
                balances?.available_balances[
                  selectedAsset as keyof typeof balances.available_balances
                ] || 0
              )
            )}
          </>
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={13}
          alignItems="center"
          display="flex"
          gap={0.5}
        >
          <CurrencyIcon currency={default_currency} size={20} />
          {isLoading ? (
            <CircularProgress size={14} />
          ) : (
            default_currency &&
            rate &&
            numeral(
              Number(
                balances?.available_balances[
                  selectedAsset as keyof typeof balances.available_balances
                ]
              ) * rate
            ).format("0,00")
          )}
        </Typography>
      </Stack>
      <Stack spacing={0.2}>
        <Typography fontWeight={600} fontSize={12}>
          Total Balance
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={13}
          alignItems="center"
          display="flex"
          gap={0.5}
        >
          <CurrencyIcon currency={selectedAsset} size={20} />
          {isLoading ? (
            <CircularProgress size={14} />
          ) : (
            formatNumber(
              balances?.balances[
                selectedAsset as keyof typeof balances.balances
              ] || 0
            )
          )}
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={13}
          alignItems="center"
          display="flex"
          gap={0.5}
        >
          <CurrencyIcon currency={default_currency} size={20} />
          {isLoading ? (
            <CircularProgress size={14} />
          ) : (
            default_currency &&
            rate &&
            numeral(
              Number(
                balances?.balances[
                  selectedAsset as keyof typeof balances.balances
                ]
              ) * rate
            ).format("0,00")
          )}
        </Typography>
      </Stack>
      <Stack spacing={0.2}>
        <Typography fontWeight={600} fontSize={12}>
          Rate
        </Typography>
        <Typography
          fontWeight={400}
          fontSize={13}
          alignItems="center"
          display="flex"
          gap={0.5}
          noWrap
        >
          <CurrencyIcon currency={selectedAsset} size={20} />1 ≈{" "}
          {isLoading ? (
            <CircularProgress size={14} />
          ) : (
            numeral(rate).format("0,00.0")
          )}
          <CurrencyIcon currency={default_currency} size={20} />
        </Typography>
      </Stack>
      {!isSmall && (
        <Box display={"flex"} justifyContent={"center"}>
          <Tooltip title="Next">
            <IconButton
              sx={{ alignSelf: "center" }}
              onClick={handleNext}
              disabled={!nextItem()}
              color="primary"
            >
              <ArrowRightIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </Stack>
  );
};

export default BalanceInfo;
