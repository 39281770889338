import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";
import { Transaction } from "types/transaction";
import { UsdEur } from "types/usd-eur";

export type Balances = {
  btc: string;
  eth: number;
  usdt: number;
  usd: string;
  eur: string;
  usdc: number;
  dai: number;
  usdc_trc20: number;
  usdt_trc20: number;
  trx: number;
  xrp: number;
};

type BalancesResponse = {
  available_balances: Balances;
  balances: Balances;
  rates: {
    btc: UsdEur;
    eth: UsdEur;
    usdt: UsdEur;
    xrp: UsdEur;
    usdc: UsdEur;
    dai: UsdEur;
  };
};

type TransactionsResponse = {
  transactions: Transaction[];
  total: number;
};

type AddressResponse = {
  address: string;
};

type TransactionsParams = {
  type: string;
  [key: string]: any;
};

export const financeApi = createApi({
  reducerPath: "financeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseHref}/finance`,
    credentials: "include",
  }),
  tagTypes: ["transactionsAll"],
  endpoints: (builder) => ({
    balances: builder.query<BalancesResponse, void>({
      query: () => `balances`,
    }),
    transactions: builder.query<TransactionsResponse, TransactionsParams>({
      query: ({ type, ...query }) =>
        `transactions/${type}${
          query
            ? "?" +
              new URLSearchParams(
                Object.fromEntries(
                  Object.entries(query).filter(([_, v]) => !!v)
                )
              ).toString()
            : ""
        }`,
      providesTags: ["transactionsAll"],
    }),
    address: builder.query<
      AddressResponse,
      | "usdt"
      | "eth"
      | "btc"
      | "usdc"
      | "trx"
      | "usdt_trc20"
      | "usdc_trc20"
      | "dai"
      | "xrp"
    >({
      query: (address) => `address/${address.toLowerCase()}`,
    }),
  }),
});

export const { useBalancesQuery, useTransactionsQuery, useAddressQuery } =
  financeApi;
