import { createTheme } from "@mui/material/styles";
import palette from "./palette";
import typography from "./typography";
import shadows, { customShadows } from "./shadows";
const theme = createTheme({
  shape: { borderRadius: 8 },
  shadows,
  customShadows,
  typography: {
    ...typography,
  },
  palette: {
    ...palette,
    white: {
      main: "#fff",
    },
    outerSpace: {
      main: "#474954",
    },
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 20,
      },
    },
  },
});

export default theme;
