import React, { useMemo } from "react";
import { Box, Drawer, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MiniDrawerStyled from "./mini-drawer-styled";
import DrawerHeader from "./drawer-header";
import { useAppDispatch, useAppSelector } from "store";
import { toggleDrawer } from "features/app/appSlice";
import DrawerContent from "./drawer-content";
const MainDrawer: React.FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const open = useAppSelector((state) => state.app.isDrawerOpen);
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const drawerContent = useMemo(() => <DrawerContent />, []);
  const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);

  const handleDrawerToggle = () => {
    dispatch(toggleDrawer());
  };

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, zIndex: 1300 }}
      aria-label="mailbox folders"
    >
      {!matchDownMD ? (
        <MiniDrawerStyled variant="permanent" open={open}>
          {drawerHeader}
          {drawerContent}
        </MiniDrawerStyled>
      ) : (
        <Drawer
          container={container}
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{ display: { xs: "block", lg: "none" } }}
          PaperProps={{
            sx: {
              bgcolor: "grey.100",
              boxSizing: "border-box",
              width: 300,
              borderRightStyle: "solid",
              borderRightColor: theme.palette.divider,
              borderRightWidth: 1,
              backgroundImage: "none",
              boxShadow: "inherit",
            },
          }}
        >
          {open && drawerHeader}
          {open && drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default MainDrawer;
