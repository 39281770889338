import React from "react";

const EthIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 163 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.4592 8.80084C75.8917 3.35496 87.5131 3.35496 96.9456 8.80085L136.865 31.8481C146.297 37.294 152.108 47.3584 152.108 58.2502V104.345C152.108 115.237 146.297 125.301 136.865 130.747L96.9456 153.794C87.5131 159.24 75.8917 159.24 66.4592 153.794L26.5401 130.747C17.1076 125.301 11.2969 115.236 11.2969 104.345V58.2502C11.2969 47.3584 17.1076 37.294 26.5401 31.8481L66.4592 8.80084Z"
        fill="url(#paint0_linear_0_612)"
      />
      <path
        d="M58.8383 82.0022L82.2303 43.1892V43.2077L82.2357 43.1892L105.624 81.9968L105.636 82.0025L82.2442 95.8297V95.8255L82.2357 95.8305L82.2303 95.8251V95.8297L58.8383 82.0022Z"
        fill="white"
      />
      <path
        d="M82.2303 119.389V119.405L58.8376 86.4414L82.2303 100.261V100.268L82.2358 100.261L105.642 86.4414L82.2358 119.405L82.2303 119.389Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_612"
          x1="81.7024"
          y1="4.71643"
          x2="81.7024"
          y2="157.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9A7DE8" />
          <stop offset="1" stopColor="#7852E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EthIcon;
