import React from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Profile from "./profile";
import BalanceInfo from "components/balanceInfo";
import { AccountBalance } from "@mui/icons-material";
import theme from "theme";

const HeaderContent = () => {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ ml: 1, overflow: "auto", flex: 1 }}>
        {isSmall ? (
          <div style={{ position: "relative" }}>
            <Tooltip title="Balance">
              <IconButton
                name="Balance"
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <AccountBalance />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              variant="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{}}
            >
              <MenuItem disableRipple>
                <BalanceInfo isSmall />
              </MenuItem>
            </Menu>
          </div>
        ) : (
          <BalanceInfo />
        )}
      </Box>
      <Profile />
    </>
  );
};

export default HeaderContent;
