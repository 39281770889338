import React from "react";
const TRXIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 164 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.9186 8.80084C76.3512 3.35496 87.9726 3.35496 97.4051 8.80085L137.324 31.8481C146.757 37.294 152.567 47.3584 152.567 58.2502V104.345C152.567 115.237 146.757 125.301 137.324 130.747L97.4051 153.794C87.9726 159.24 76.3512 159.24 66.9186 153.794L26.9996 130.747C17.567 125.301 11.7563 115.236 11.7563 104.345V58.2502C11.7563 47.3584 17.567 37.294 26.9996 31.8481L66.9186 8.80084Z"
        fill="url(#paint0_linear_0_606)"
      />
      <path
        d="M118.046 66.1501C114.474 62.8514 109.532 57.814 105.506 54.2414L105.268 54.0747C104.872 53.7565 104.425 53.5069 103.946 53.3364V53.3364C94.2409 51.5263 49.0715 43.083 48.1902 43.1902C47.9433 43.2248 47.7073 43.3143 47.4995 43.4522L47.2733 43.6308C46.9947 43.9138 46.7831 44.2556 46.654 44.6312L46.5945 44.786V45.6315V45.7625C51.6795 59.9218 71.7574 106.306 75.7111 117.19C75.9492 117.929 76.4018 119.334 77.2473 119.405H77.4378C77.8904 119.405 79.8196 116.857 79.8196 116.857C79.8196 116.857 114.307 75.0339 117.796 70.5801C118.248 70.0314 118.647 69.4413 118.987 68.8176C119.074 68.3296 119.033 67.8273 118.868 67.3599C118.703 66.8924 118.42 66.4756 118.046 66.1501V66.1501ZM88.6677 71.0207L103.387 58.8143L112.02 66.7693L88.6677 71.0207ZM82.9515 70.2228L57.61 49.4542L98.6114 57.0161L82.9515 70.2228ZM85.238 75.665L111.175 71.4851L81.5225 107.211L85.238 75.665ZM54.1684 51.5263L80.8318 74.1526L76.9734 107.235L54.1684 51.5263Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_606"
          x1="82.1619"
          y1="4.71643"
          x2="82.1619"
          y2="157.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9534A" />
          <stop offset="1" stopColor="#C6342A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default TRXIcon;
