import React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading: React.FC = () => {
  return (
    <Box sx={{ position: "fixed", inset: 0 }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    </Box>
  );
};

export default Loading;
