import React from "react";

const USDIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 163 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_2261)">
        <mask
          id="mask0_2_2261"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="13"
          y="3"
          width="143"
          height="155"
        >
          <path
            d="M69.2568 7.80069C78.6893 2.3548 90.3107 2.3548 99.7432 7.80069L139.838 30.9493C149.27 36.3952 155.081 46.4596 155.081 57.3514V103.649C155.081 114.54 149.27 124.605 139.838 130.051L99.7432 153.199C90.3107 158.645 78.6893 158.645 69.2568 153.199L29.1622 130.051C19.7296 124.605 13.9189 114.54 13.9189 103.649V57.3514C13.9189 46.4596 19.7296 36.3952 29.1622 30.9493L69.2568 7.80069Z"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_2_2261)">
          <path
            d="M80.9999 161.999C125.735 161.999 162 125.734 162 80.9996C162 36.2647 125.735 0 80.9999 0C36.265 0 0 36.2647 0 80.9996C0 125.734 36.265 161.999 80.9999 161.999Z"
            fill="#F0F0F0"
          />
          <path
            d="M77.478 81H161.999C161.999 73.6894 161.025 66.6069 159.209 59.8696H77.478V81Z"
            fill="#D80027"
          />
          <path
            d="M77.478 38.7384H150.111C145.153 30.6472 138.813 23.4955 131.421 17.6079H77.478V38.7384Z"
            fill="#D80027"
          />
          <path
            d="M80.9998 162C100.063 162 117.585 155.412 131.422 144.392H30.5786C44.4151 155.412 61.9368 162 80.9998 162Z"
            fill="#D80027"
          />
          <path
            d="M11.8878 123.26H150.11C154.092 116.765 157.179 109.664 159.209 102.13H2.78955C4.81998 109.664 7.90714 116.765 11.8878 123.26Z"
            fill="#D80027"
          />
          <path
            d="M37.5208 12.6492H44.9022L38.0362 17.6373L40.6589 25.7085L33.7932 20.7204L26.9275 25.7085L29.193 18.7359C23.1477 23.7715 17.8492 29.6712 13.4828 36.2448H15.8479L11.4774 39.4199C10.7964 40.5558 10.1434 41.7097 9.51748 42.8808L11.6045 49.304L7.71085 46.4751C6.74298 48.5257 5.85765 50.6226 5.06191 52.763L7.36118 59.8403H15.8479L8.98183 64.8284L11.6045 72.8996L4.7388 67.9115L0.626171 70.8997C0.214529 74.2086 0 77.579 0 80.9996H80.9999C80.9999 36.2651 80.9999 30.9912 80.9999 0C64.9988 0 50.0824 4.64166 37.5208 12.6492ZM40.6589 72.8996L33.7932 67.9115L26.9275 72.8996L29.5502 64.8284L22.6841 59.8403H31.1708L33.7932 51.7692L36.4156 59.8403H44.9022L38.0362 64.8284L40.6589 72.8996ZM38.0362 41.2329L40.6589 49.304L33.7932 44.3159L26.9275 49.304L29.5502 41.2329L22.6841 36.2448H31.1708L33.7932 28.1736L36.4156 36.2448H44.9022L38.0362 41.2329ZM69.7133 72.8996L62.8475 67.9115L55.9818 72.8996L58.6045 64.8284L51.7385 59.8403H60.2252L62.8475 51.7692L65.4699 59.8403H73.957L67.0906 64.8284L69.7133 72.8996ZM67.0906 41.2329L69.7133 49.304L62.8475 44.3159L55.9818 49.304L58.6045 41.2329L51.7385 36.2448H60.2252L62.8475 28.1736L65.4699 36.2448H73.957L67.0906 41.2329ZM67.0906 17.6373L69.7133 25.7085L62.8475 20.7204L55.9818 25.7085L58.6045 17.6373L51.7385 12.6492H60.2252L62.8475 4.57806L65.4699 12.6492H73.957L67.0906 17.6373Z"
            fill="#0052B4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2_2261">
          <rect width="162" height="162" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default USDIcon;
