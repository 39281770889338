import React from "react";

const USDCIcon: React.FC<{ size?: number }> = ({ size = 51 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 163 163"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.2702 8.80084C75.7028 3.35496 87.3241 3.35496 96.7567 8.80085L136.676 31.8481C146.108 37.294 151.919 47.3584 151.919 58.2502V104.345C151.919 115.237 146.108 125.301 136.676 130.747L96.7567 153.794C87.3241 159.24 75.7027 159.24 66.2702 153.794L26.3511 130.747C16.9186 125.301 11.1079 115.236 11.1079 104.345V58.2502C11.1079 47.3584 16.9186 37.294 26.3512 31.8481L66.2702 8.80084Z"
        fill="url(#paint0_linear_0_614)"
      />
      <path
        d="M51.9543 70.3121C45.831 86.5702 54.2767 104.939 70.7455 110.851C71.379 111.273 72.0124 112.118 72.0124 112.751V115.707C72.0124 116.129 72.0124 116.341 71.8011 116.552C71.5903 117.396 70.7455 117.818 69.9008 117.396C58.0772 113.596 48.9981 104.517 45.1975 92.693C38.8634 72.635 49.8428 51.3096 69.9008 44.9754C70.1121 44.7646 70.5342 44.7646 70.7455 44.7646C71.5903 44.9754 72.0124 45.6089 72.0124 46.4536V49.4094C72.0124 50.4654 71.5903 51.0988 70.7455 51.5209C62.089 54.688 55.1214 61.4443 51.9543 70.3121Z"
        fill="white"
      />
      <path
        d="M91.2262 45.8202C91.437 44.9754 92.2817 44.5533 93.1264 44.9754C104.739 48.776 114.029 57.8551 117.83 69.89C124.164 89.9481 113.184 111.273 93.1264 117.608C92.9151 117.818 92.493 117.818 92.2817 117.818C91.437 117.608 91.0149 116.974 91.0149 116.129V113.174C91.0149 112.118 91.437 111.484 92.2817 111.062C100.938 107.895 107.906 101.139 111.073 92.2709C117.196 76.0129 108.751 57.6438 92.2817 51.7322C91.6483 51.3096 91.0149 50.4654 91.0149 49.6207V46.6649C91.0149 46.2423 91.0149 46.0315 91.2262 45.8202Z"
        fill="white"
      />
      <path
        d="M82.1418 77.7009C91.0095 78.7565 95.4434 81.2901 95.4434 88.6799C95.4434 94.3806 91.2208 98.8146 84.8867 99.8706V104.938C84.6754 105.993 84.042 106.627 83.1973 106.627H80.0303C78.9748 106.416 78.3414 105.782 78.3414 104.938V99.8706C71.3739 98.8146 67.9955 95.0141 67.1513 89.7359V89.5246C67.1513 88.6799 67.7847 88.0464 68.6289 88.0464H72.2186C72.852 88.0464 73.4854 88.4691 73.6962 89.3133C74.3296 92.4804 76.2298 94.8033 81.7197 94.8033C85.731 94.8033 88.6872 92.4809 88.6872 89.1025C88.6872 85.7241 86.787 84.4572 80.875 83.4017C72.0073 82.3457 67.7847 79.6012 67.7847 72.6336C67.7847 67.3549 71.796 63.1323 78.1301 62.2876V57.4315C78.3414 56.3755 78.9748 55.7421 79.8195 55.7421H82.9865C84.042 55.9534 84.6754 56.5868 84.6754 57.4315V62.4989C89.5314 62.921 93.3319 66.5102 94.1766 71.1554V71.3667C94.1766 72.211 93.5432 72.8444 92.6984 72.8444H89.3206C88.6872 72.8444 88.0538 72.4223 87.8425 71.7888C86.787 68.6217 84.6754 67.3549 80.875 67.3549C76.6524 67.3549 74.5409 69.2547 74.5409 72.211C74.5409 75.1673 75.8077 76.8562 82.1418 77.7009Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_0_614"
          x1="81.5134"
          y1="4.71643"
          x2="81.5134"
          y2="157.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D83EF" />
          <stop offset="1" stopColor="#145CEB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default USDCIcon;
