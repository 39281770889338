import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  useMediaQuery,
} from "@mui/material";

import AppBarStyled from "./app-bar-styled";
import HeaderContent from "./header-content";
import MenuFoldOutlined from "@mui/icons-material/MenuOpen";
import MenuUnfoldOutlined from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "store";
import { toggleDrawer } from "features/app/appSlice";
import "./favicon";
const iconBackColor = "grey.100";
const iconBackColorOpen = "grey.200";

const Header = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.app.isDrawerOpen);
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

  const handleToggleDrawer = () => {
    dispatch(toggleDrawer());
  };
  const mainHeader = (
    <Toolbar
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Tooltip title="Toggle drawer" placement="bottom">
        <IconButton
          disableRipple
          aria-label="open drawer"
          onClick={handleToggleDrawer}
          edge="start"
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
            ml: { xs: 0, lg: -2 },
          }}
        >
          {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </IconButton>
      </Tooltip>
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: "fixed",
    color: "inherit",
    elevation: 0,
    sx: {
      borderBottomStyle: "dashed",
      borderBottomColor: theme.palette.divider,
    },
  };

  return (
    <>
      {!matchDownMD ? (
        // @ts-ignore
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        // @ts-ignore
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};
export default Header;
