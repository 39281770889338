import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseHref } from "service/api";

type ListResponse = {
  countries: {
    value: string;
    label: string;
  }[];
  result: boolean;
};

export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseHref,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    listCountries: builder.query<ListResponse, void>({
      query: () => `countries`,
    }),
  }),
});

export const { useListCountriesQuery } = countriesApi;
