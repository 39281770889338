import React, { useCallback, useState } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { SnackbarKey, useSnackbar } from "notistack";
import { useEffect } from "react";
import { removeNotification } from "features/notifications/notificationsSlice";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppRouter from "router";
import { LayoutGroup } from "framer-motion";
import { Helmet } from "react-helmet";
import { useIdleTimer } from "react-idle-timer";
import { logout } from "service/api";
import { useAccountQuery } from "features/auth/authApi";
import { Tooltip } from "@mui/material";
const clientId =
  process.env.REACT_APP_GET_ID_CLIENT_ID || "3b289f52531cff075d9e56f4fcc36958";
const clientName = process.env.REACT_APP_GET_ID_CLIENT_NAME || "uppex";
const getidUrl = `https://${clientName}.regily.com/${clientId}.js`;

const App = () => {
  const [count, setCount] = useState<number>(0);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const notifications = useAppSelector((state) => state.notifications.items);

  const { data: account } = useAccountQuery(undefined, {
    pollingInterval: 60000,
  });

  const idleLogoutTimeout =
    account && account.user ? account.user.idle_logout_time : 15;

  const onAction = () => {
    setCount(count + 1);
  };

  const onIdle = () => {
    logout().finally(() => {
      window.location.reload();
    });
  };

  useIdleTimer({
    onIdle,
    onAction,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    timeout: Number(idleLogoutTimeout) * 60 * 1000,
  });

  const dispatch = useAppDispatch();

  const handleCloseSnackbar = useCallback(
    (key: SnackbarKey) => () => {
      closeSnackbar(key);
    },
    [closeSnackbar]
  );

  useEffect(() => {
    if (account && account.user && !account.user.is_active) {
      logout().finally(() => {
        window.location.reload();
      });
    }
  }, [account]);

  useEffect(() => {
    (window as any).onCheckinLoad = (checkin: any) => {
      checkin.settings.setLang("en");
      checkin.dataFlow.setOnComplete((obj: any) => {
        console.log("Complete dataset captured:", obj.data);
        var url = `${process.env.REACT_APP_API_BASE_HREF}/getid/complete`;
        fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj.data),
        })
          .then(function (data) {
            if (data.status === 200) {
              console.log("Save successful!", data);
            }
          })
          .catch(function (err) {
            return checkin.generate.dataError.unknown(); // In case something goes wrong, display an error
          });
      });
    };
    notifications.forEach((notification) => {
      enqueueSnackbar(notification.message, {
        variant: notification.type,
        autoHideDuration: 3000,
        action: (id) => (
          <Tooltip title="Dismiss">
            <IconButton color="white" onClick={handleCloseSnackbar(id)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ),
      });
      dispatch(removeNotification(notification.message));
    });
  }, [notifications, enqueueSnackbar, dispatch, handleCloseSnackbar]);

  return (
    <LayoutGroup>
      <AppRouter />
      <Helmet>
        <script src={getidUrl} async></script>
      </Helmet>
    </LayoutGroup>
  );
};

export default App;
