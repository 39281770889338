import EthIcon from "components/icons/eth";
import USDTIcon from "components/icons/usdt";
import BTCIcon from "../icons/btc";
import React from "react";
import USDIcon from "components/icons/usd";
import EuroIcon from "components/icons/euro";
import USDCIcon from "components/icons/usdc";
import DAIIcon from "components/icons/dai";
import RippleIcon from "components/icons/ripple";
import TRXIcon from "components/icons/trx";
type Props = {
  currency?: string;
  size?: number;
};
const CurrencyIcon: React.FC<Props> = ({ size = 51, currency }) => {
  const icon = () => {
    switch ((currency || "").toLowerCase()) {
      case "btc":
        return <BTCIcon size={size} />;
      case "eth":
        return <EthIcon size={size} />;
      case "usdt":
      case "usdt_trc20":
        return <USDTIcon size={size} />;
      case "usd":
        return <USDIcon size={size} />;
      case "eur":
        return <EuroIcon size={size} />;
      case "usdc":
      case "usdc_trc20":
        return <USDCIcon size={size} />;
      case "trx":
        return <TRXIcon size={size} />;
      case "dai":
        return <DAIIcon size={size} />;
      case "xrp":
        return <RippleIcon size={size} />;
      default:
        return <span>{currency}</span>;
    }
  };
  return icon();
};

export default CurrencyIcon;
