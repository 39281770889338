import { useAccountQuery } from "features/auth/authApi";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const OtpChangedCheck: React.FC = () => {
  const { data: account } = useAccountQuery();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (account?.user.otp_channel_changed && location.pathname !== "/profile") {
      navigate("/profile");
    }
  });
  return null;
};

export default OtpChangedCheck;
